"use client";

import { cn } from "@/lib/utils";
import { Icons } from "@/components/icons";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useTransition, useState } from "react";
import { signInWithMagicLink, signInWithGoogle } from "@/lib/supabase/auth";
import { useSearchParams, useRouter } from "next/navigation";
import { formatErrorMessage } from "./format-error-message";

interface UserAuthFormProps extends React.HTMLAttributes<HTMLDivElement> {}

export function UserAuthForm({ className, ...props }: UserAuthFormProps) {
  const [isSignInWithMagicLinkPending, startSignInWithMagicLink] =
    useTransition();
  const [isSignInWithGooglePending, startSignInWithGoogle] = useTransition();
  const [email, setEmail] = useState("");
  const [sent, setSent] = useState(false);
  const externalError = new Error(
    formatErrorMessage(useSearchParams().get("error") || "")
  );
  const [error, setError] = useState<Error>(externalError);
  const { push } = useRouter();

  async function onSubmit(event: React.SyntheticEvent) {
    event.preventDefault();
    setError(new Error());
    setSent(false);
    startSignInWithMagicLink(async () => {
      if (email.endsWith("@neulabs.com")) {
        const { error } = JSON.parse(await signInWithMagicLink({ email }));
        if (error) {
          setError(error);
        } else {
          setSent(true);
        }
      } else {
        setError(new Error("Invalid email"));
      }
    });
  }

  async function onSignInWithGoogle() {
    startSignInWithGoogle(async () => {
      const { data, error } = JSON.parse(await signInWithGoogle());
      if (error) {
        setError(error);
      } else if (data?.url) {
        push(data.url);
        await Promise.resolve(() => setTimeout(() => {}, 2000));
      }
    });
  }

  return (
    <div className={cn("grid gap-6", className)} {...props}>
      <form onSubmit={onSubmit}>
        <div className="grid gap-2">
          <div className="grid gap-1">
            <Label className="sr-only" htmlFor="email">
              Email
            </Label>
            <Input
              id="email"
              placeholder="name@neulabs.com"
              type="email"
              autoCapitalize="none"
              autoComplete="email"
              autoCorrect="off"
              required
              value={email}
              onChange={({ target: { value } }) => setEmail(value)}
              disabled={isSignInWithMagicLinkPending}
            />
          </div>
          <Button disabled={isSignInWithMagicLinkPending}>
            {isSignInWithMagicLinkPending && (
              <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
            )}
            Sign in with magic link
          </Button>
        </div>
      </form>
      {sent && (
        <p className="text-sm text-center text-green-600">
          Check your email for the magic link
        </p>
      )}
      {error?.message && (
        <p className="text-sm text-center text-red-600">{error.message}</p>
      )}
      <div className="relative">
        <div className="absolute inset-0 flex items-center">
          <span className="w-full border-t" />
        </div>
        <div className="relative flex justify-center text-xs uppercase">
          <span className="bg-background px-2 text-muted-foreground">
            Or continue with
          </span>
        </div>
      </div>
      <Button
        variant="destructive"
        type="button"
        disabled={isSignInWithGooglePending}
        onClick={onSignInWithGoogle}
      >
        {isSignInWithGooglePending && (
          <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
        )}
        <Icons.google className="mr-2 h-4 w-4" />
        Google
      </Button>
    </div>
  );
}
