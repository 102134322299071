export function formatErrorMessage(code: string) {
  switch (code) {
    case "invalid-code":
      return "Invalid session token. Please try again.";
    case "generic-error":
      return "An unexpected error occurred. Please try again.";
    default:
      return "";
  }
}
