import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

export const valueFormatter = (number: number) => {
  const currency = "€";
  const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/;
  const item = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
  ].findLast((item) => number >= item.value);
  const formattedNumber = item
    ? (number / item.value).toFixed(2).replace(regexp, "").concat(item.symbol)
    : "0";
  return `${currency} ${formattedNumber}`;
};

export const getColors = (length: number) => {
  const colors = [
    "blue",
    "slate",
    "red",
    "purple",
    "yellow",
    "lime",
    "orange",
    "fuchsia",
  ];

  return colors.slice(0, length);
};
